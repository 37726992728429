let slideIndex = 0;
let timerId = undefined;

function showSlide(slide) {
  if (slide === undefined) {
    slide = slideIndex + 1;
  }

  if (timerId !== undefined) {
    clearTimeout(timerId);
    timerId = undefined;
  }

  const slides = document.getElementsByClassName("carousel__slide");
  if (slide >= slides.length) {
    slideIndex = 0;
  } else if (slide < 0) {
    slideIndex = slides.length;
  } else {
    slideIndex = slide;
  }

  for (let i = 0; i < slides.length; ++i) {
    slides[i].style.display = "none";
  }

  const dots = document.getElementsByClassName("carousel__navigation-button");
  for (let i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }

  slides[slideIndex].style.display = "block";
  dots[slideIndex].className += " active";

  timerId = setTimeout(showSlide, 4000);
}

showSlide(0);

window.showSlide = showSlide;
